<template>
    <div
        v-if="value"
        class="textPopup"
    >
        <c-button @click="value = false;" class="textPopup__close">
            <svg class="ico mx-auto ico--medium mb-3"><use xlink:href="#x"></use></svg>
            Zavřít
        </c-button>
        <div class="textPopup__content">
            <slot>
            </slot>
        </div>
    </div>
</template>

<script>
import CButton from "@/components/CButton";

export default {
    name: "TextPopup",
    components: {CButton},
    props: {
        modelValue: {
            type: Boolean
        },
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
                this.$emit(value ? 'open' : 'close')
            }
        }
    }
}
</script>

<style scoped>

</style>
