import axios from "@/plugins/axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getRegions() {
            const response = await axios.get('/dealers/regions');
            return response.data;
        },
        async getDealers() {
                const response = await axios.get('/dealers');
                return response.data;
        },
        async createTestDrive(context, data) {
            const response = await axios.post('/test-drive', data);
            return response.data;
        }
    },
};
