<template>
  <div class="welcome">
    <div class="welcome__container">
      <h1 class="welcome__title"></h1>
      <p class="welcome__subTitle">Škoda</p>
      <p class="welcome__subSubTitle">
        Nabité dny
      </p>
      <p class="welcome__subSubSubTitle"></p>
    </div>
    <p class="welcome__perex">Vyhrajte Škoda Enyaq Coupé RS iV na rok a další ceny</p>

    <form @submit.prevent="sign" class="welcome__form">
      <c-text-input v-model="firstName" label="Jméno" class="input" required></c-text-input>
      <c-text-input v-model="lastName" label="Příjmení" class="input" required></c-text-input>
      <c-text-input
          v-model="email"
          label="E-mail"
          class="input"
          type="email"
          :class="{'text-error': (this.sendError && this.sendError.field === 'email')}"
          required
      ></c-text-input>
      <phone-input
          v-model="phone"
          label="Telefon"
          :class="{'text-error': phoneError || (this.sendError && this.sendError.field === 'phone')}"
          @error="errorEvent"
          required
      ></phone-input>

      <p class="mb-5">
        Odesláním formuláře potvrzujete, že jste se seznámil s Pravidly soutěže a souhlasíte že budeme Vaše osobní údaje
        zpracovávat za účelem Pořádání soutěže a hlasování v soutěži. Více informací, včetně Vašich práv, je uvedeno
        <a class="text-white" target="_blank" href="https://www.skoda-auto.cz/o-spolecnosti/pravidla-soutezi">Zde</a>.
      </p>

      <c-checkbox class="mb-5" v-model="marketing">
        <template v-slot:label>
                <span>
                   Tímto uděluji
                  <a
                      class="text-white"
                      target="_blank"
                      href="https://www.skoda-auto.cz/sluzby/memorandum-realizace-marketingovych-aktivit"
                  >souhlas se zpracováním svých osobních údajů</a>
                  za účelem oslovování s nabídkou produktů, ...
                  <c-button class="text-white text-decoration-underline"
                            @click.stop.prevent="showAgreementPopup = true"
                  >zobrazit více</c-button>
                </span>
        </template>
      </c-checkbox>

      <text-popup
          v-model="showAgreementPopup"
          @close="showAgreementPopup = false"
      >
        <p>
          Tímto uděluji
          <a
              class="text-white"
              target="_blank"
              href="https://www.skoda-auto.cz/sluzby/memorandum-realizace-marketingovych-aktivit"
          >souhlas se zpracováním svých osobních údajů</a>
          za účelem oslovování s nabídkou produktů, služeb a výzkumu trhu značky Škoda Auto, včetně informování o
          akcích, soutěžích, novinkách a zasílání přání k svátkům, a to i na základě informací o používání produktů a
          služeb. Za účelem obohacení zákaznických dat může Škoda Auto také předávat mé osobní údaje třetím stranám jako
          je Volkswagen Financial Services AG, preferovaný dealer a importér odpovědný za daný trh.
        </p>
      </text-popup>

      <p class="text-error-input mb-4" v-if="error">
        <span class="text-error-active" v-html="error"></span>
      </p>
      <c-button type="submit" class="btn btn--green">
        Pokračovat
      </c-button>
    </form>
  </div>
</template>

<script>
import CTextInput from "@/components/CTextInput";
import CButton from "@/components/CButton";
import img from "@/assets/images/emobility-font.png";
import PhoneInput from "@/components/PhoneInput";
import TextPopup from "@/components/TextPopup";
import CCheckbox from "@/components/CCheckbox.vue";

export default {
  name: "WelcomeView",
  components: {CCheckbox, PhoneInput, CButton, CTextInput, TextPopup},
  data: () => {
    return {
      showAgreementPopup: false,
      emobilityfont: img,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      phoneError: '',
      sendError: null,
      marketing: false
    }
  },
  computed: {
    error() {
      return [this.phoneError, this.sendError ? this.sendError.message : null].filter(error => !!error).join('<br>');
    }
  },
  methods: {
    async sign() {
      this.sendError = '';
      if (this.phoneError) {
        return;
      }
      const error = await this.$store.dispatch('users/signValidateUser', {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        marketing: this.marketing,
      });
      if (!error) {
        this.$router.push({name: 'agreements'});
        return;
      }
      this.sendError = error;
    },
    errorEvent(message) {
      this.phoneError = message;
    }
  }
}
</script>

<style scoped>

</style>
