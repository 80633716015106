<template>
    <div class="welcome pt-5">
        <!-- <div class="welcome__container">
            <h1 class="welcome__title"></h1>
            <p class="welcome__subTitle">Škoda</p>
            <p class="welcome__subSubTitle">
                Nabité dny
            </p>
            <p class="welcome__subSubSubTitle">vyhrajte</p>
        </div>
        <p class="welcome__perex">Škoda Enyaq Coupé RS iV na rok a další ceny</p> -->


        <form action="" class="welcome__form"  @submit.prevent="complete">
            <h3 class="h4 mb-5">Vyberte vašeho autorizovaného prodejce vozů Škoda</h3>
            <c-select v-model="region" label="Vyberte kraj" :options="regions" class="input select"/>
            <c-select v-model="dealer" label="Vyberte autorizovaného prodejce" :options="sellers" class="input select"/>


            <h3 class="h4 mb-5">Chcete vyhrát Škoda Enyaq Coupé RS iV na rok? Přijďte na testovací jízdu</h3>
            <!-- TODO: Luke, tenhle checkbox jsem přidal a nastavil aby mohl zapínat text pod ním, další logiku jsem k němu neřešil -->
            <c-checkbox v-model="testDrive">
                <template v-slot:label>
                        <span>
                            Mám zájem
                        </span>
                </template>
            </c-checkbox>

            <div v-if="testDrive" class="mb-5">
                Po odeslání formuláře budeme Vaše osobní údaje zpracovávat za účelem Zajišťování testovacích jízd.
                Více informací, včetně Vašich práv, je uvedeno v
                <a class="text-white" target="_blank" href="https://www.skoda-auto.cz/sluzby/memorandum-zajistovani-testovacich-jizd">
                    Informačním memorandu
                </a>
                .
            </div>

            <div class="mt-auto mb-0 pb-5">
                <div class="my-5">
                    <c-button class="btn btn--green px-3" :disabled="!enableGame">
                        Začít poznávat
                    </c-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import CSelect from "@/components/CSelect.vue";
// import TextPopup from "@/components/TextPopup";
import {mapGetters} from "vuex";
import CCheckbox from "@/components/CCheckbox.vue";
import CButton from "@/components/CButton.vue";

export default {
    name: "AgreementsView",
    components: {CButton, CCheckbox, CSelect},

    data: () => {
        return {
            region: '',
            regions: [],
            dealer: '',
            dealers: [],
            showAgreementPopup: false,
            showAgreementPopup2: false,
            testDrive: false,
        }
    },
    computed: {
        ...mapGetters("users", ["isFilledSign"]),
        enableGame() {
            return this.region && this.dealer;
        },
        sellers() {
            let sellers = this.dealers;
            if (this.region) {
                sellers = this.dealers.filter(dealer => dealer.region === this.region);
            }
            return sellers.map((dealer) => ({
                id: dealer.id,
                name: `${dealer.name} - ${dealer.city}, ${dealer.zip}, ${dealer.street}`
            }));
        },
        enableTestRide() {
            return this.testRide && this.testRide;
        },
        enableWithoutGame() {
            return !this.gdpr && this.rules;
        }
    },
    watch: {
        region() {
            this.dealer = '';
        }
    },
    methods: {
        async complete() {
            const error = await this.$store.dispatch("users/signUser", {
                region: this.region,
                dealer: this.dealer,
                testDrive: this.testDrive,
            });
            if (!error) {
                this.$router.push({name: 'venue-plan'});
                return;
            }
            this.sendError = error;
        },
        async loadData() {
            this.regions = await this.$store.dispatch('dealers/getRegions');
            this.dealers = await this.$store.dispatch('dealers/getDealers');
        },
    },
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            if (!vm.isFilledSign) {
                vm.$router.push({name: "welcome"});
            }
            await vm.loadData();
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.loadData();
        next();
    },
}
</script>

<style scoped>

</style>
