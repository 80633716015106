import {createStore} from 'vuex'
import users from "@/store/users";
import reach from "@/store/reach";
import VuexPersistence from 'vuex-persist'
import chargingNetwork from "@/store/chargingNetwork";
import cooperation from "@/store/cooperation";
import charging from "@/store/charging";
import sustainability from "@/store/sustainability";
import vision from "@/store/vision";
import skodaAndYou from "@/store/skodaAndYou";
import axios from "@/plugins/axios";
import dealers from "@/store/dealers";


const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})
const snapshot = function (store) {
    store.subscribe((mutation, state) => {
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
        if (state.users.authorization) {
            axios.post('/users/store-state', {
                state: Object.fromEntries(Object.entries(state).filter(([key]) => key !== 'users'))
            })
        }
    })
};

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {
        reset(context) {
            Object.keys(context.rootState).forEach(
                (namespace) => context.commit(namespace+'/RESET')
            )
        }
    },
    modules: {
        users,
        reach,
        chargingNetwork,
        cooperation,
        charging,
        sustainability,
        vision,
        skodaAndYou,
        dealers
    },
    plugins: [vuexLocal.plugin, snapshot]
});
