<template>
    <label>
        <span v-if="label">{{label}}</span>
        <input v-model="innerValue" :type="type" :required="required" :placeholder="placeholder">
    </label>
</template>

<script>
export default {
    name: "CTextInput",
    props: {
        label: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        modelValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },

    },
    emits: ['update:modelValue'],
    computed: {
        innerValue: {
            get() {
                return this.modelValue
            },
            async set(value) {
                await this.$emit('update:modelValue', value);
            }
        }
    }
}
</script>

<style scoped>

</style>
