<template>
    <div class="completed">
        <div class="completed__container">
            <div class="completed__check">
            <img :src="ccheck">
            </div>
            <h1 class="completed__title">
                A je to!
            </h1>
            <p class="completed__subTitle">Vaše cesta za poznáním eMobility je u konce.</p>
            <p class="completed__perex"><strong>Ukažte svůj výsledek našim<br>hosteskám a získáte od nás <br>jednu z cen.</strong></p>
            <div class="completed__car">
                <img :src="ccar">
            </div>
            <p v-if="isGameEnabled" class="completed__perex mb-10">
                Doražte k vašemu nejbližšímu autorizovanému prodejci na testovací jízdu s
                elektromobilem a zapojte se do soutěže <br>o <strong>Škoda Enyaq Coupé RS iV na rok.</strong>
            </p>
            <c-button class="btn btn--borderWhite" @click="complete">
                Začít znovu
            </c-button>
        </div>
    </div>

</template>

<script>
import CButton from "@/components/CButton";
import cfont from "@/assets/images/completed-font.png";
import ccar from "@/assets/images/completed-car.png";
import ccheck from "@/assets/images/completed-check.png";
import {mapGetters} from "vuex";

export default {
    name: "CompletedView",
    components: {CButton},
    data() {
       return {
        cfont: cfont,
        ccar: ccar,
        ccheck: ccheck
    }},
    computed: {
       ...mapGetters(
              'users',
              ['isGameEnabled']
       )
    },
    methods: {
        async complete() {
             await this.$store.dispatch('users/logout');
             this.$router.push({name: 'welcome'})
        }
    }
}
</script>

<style scoped>

</style>
