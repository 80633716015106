<template>
    <label>
        <span v-if="label">{{label}}</span>
        <select v-model="innerValue" :required="required">
            <option
                v-for="option in options"
                :key="option[valueAttribute]"
                :value="option[valueAttribute]"
                :selected="option[valueAttribute] === innerValue"
            >
                <slot name="option" :option="option">
                    {{option[textAttribute]}}
                </slot>
            </option>
        </select>
    </label>
</template>

<script>
export default {
    name: "CSelect",
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        valueAttribute: {
            type: String,
            default: 'id'
        },
        textAttribute: {
            type: String,
            default: 'name'
        },
        required: {
            type: Boolean,
            default: false
        },

    },
    emits: ['update:modelValue'],
    computed: {
        innerValue: {
            get() {
                return this.modelValue
            },
            async set(value) {
                await this.$emit('update:modelValue', value);
            }
        }
    }
}
</script>

<style scoped>

</style>
