<template>
    <div class="testDrive">
        <div class="testDrive__header">
            <h1 class="testDrive__title">Otestujte vůz iV a vyhrajte Škoda Enyaq Coupé RS iV na rok</h1>
        </div>


        <div class="container">

            <form class="testDrive__form mb-5" @submit.prevent="submit">
                <h3 class="h4 mb-5">Vyberte prodejce</h3>

                <div class="row mb-5">
                    <div class="col-sm-6">
                        <c-select v-model="region" label="Vyberte kraj" :options="regions" class="input select"/>
                    </div>
                    <div class="col-sm-6">
                        <c-select v-model="seller" label="Vyberte autorizovaného prodejce Škoda *" :options="sellers"
                                  required class="input select"/>
                    </div>
                </div>

                <h3 class="h4 mb-4">Vaše kontaktní informace</h3>
                <div class="row mb-5 align-items-end">
                    <div class="col-sm-6">
                        <c-text-input v-model="name" label="Jméno *" class="input" required/>
                    </div>
                    <div class="col-sm-6">
                        <c-text-input v-model="surname" label="Příjmení *" class="input" required/>
                    </div>
                    <div class="col-sm-6">
                        <c-text-input v-model="email" label="E-mailová adresa *" type="email" class="input" required/>
                    </div>
                    <div class="col-sm-6">
                        <phone-input v-model="phone" label="Kontaktní telefon *" required/>
                    </div>
                </div>

                <h3 class="h4 mb-4">Soutěžní otázka</h3>
                <div class="row mb-5 align-items-end">
                    <div class="col-sm-12">
                        <c-number-input
                            v-model="answer"
                            label="Kolik testovacích jízd v rámci akce Škoda Nabité dny uskutečníme v období od 1. 6. do 31. 10. 2023?" p
                            laceholder="Váš tip"
                            class="input"
                            min="0"
                            max="1000000"
                            required
                        />
                    </div>

                </div>

                <p class="mb-5">
                Odesláním formuláře potvrzujete, že jste se seznámil s Pravidly soutěže a souhlasíte že budeme Vaše osobní údaje
                zpracovávat za účelem Pořádání soutěže a hlasování v soutěži. Více informací, včetně Vašich práv, je uvedeno
                <a class="" target="_blank" href="https://www.skoda-auto.cz/o-spolecnosti/pravidla-soutezi">Zde</a>.
                </p>



                <div class="mb-5">
                    Po odeslání formuláře budeme Vaše osobní údaje zpracovávat za účelem Zajišťování testovacích jízd.
                    Více informací, včetně Vašich práv, je uvedeno v <a
                        href="https://www.skoda-auto.cz/sluzby/memorandum-zajistovani-testovacich-jizd" target="_blank">Informačním
                    memorandu</a>.
                </div>

                <div class="mb-5">
                  <c-checkbox v-model="enableDraw">
                    <template v-slot:label>
                        <span>
                            Tímto uděluji <a class="" target="_blank" href="https://www.skoda-auto.cz/sluzby/memorandum-realizace-marketingovych-aktivit">souhlas se zpracováním svých osobních údajů</a> za účelem oslovování s nabídkou produktů, ... <a type="button" href="#" class="text-decoration-underline" @click.stop.prevent="showAgreementPopup = true">zobrazit více</a>
                        </span>
                    </template>
                  </c-checkbox>
                </div>

                <text-popup
                    v-model="showAgreementPopup"
                    @close="showAgreementPopup = false"
                >
                    <p>Tímto uděluji <a href="https://www.skoda-auto.cz/sluzby/memorandum-realizace-marketingovych-aktivit"  class="text-white" target="_blank">souhlas se zpracováním svých osobních údajů</a> za účelem oslovování s nabídkou produktů, služeb a výzkumu trhu značky Škoda Auto, včetně informování o akcích, soutěžích, novinkách a zasílání přání k svátkům, a to i na základě informací o používání produktů a služeb. Za účelem obohacení zákaznických dat může Škoda Auto také předávat mé osobní údaje třetím stranám jako je Volkswagen Financial Services AG, preferovaný dealer a importér odpovědný za daný trh.</p>
                </text-popup>

                <c-button
                        class="btn btn--green"
                        type="submit"
                        :disabled="isSubmiting"
                >
                    Odeslat
                </c-button>

            </form>
        </div>

        <div class="bg-dark-green mt-auto py-4">
            <div class="container">
                <div class="text-gray">Copyright ©2023 Škoda Auto a.s.</div>
            </div>
        </div>
    </div>
</template>

<script>
import CSelect from "@/components/CSelect.vue";
import CTextInput from "@/components/CTextInput.vue";
import PhoneInput from "@/components/PhoneInput.vue";
import CButton from "@/components/CButton.vue";
import TextPopup from "@/components/TextPopup";
import CNumberInput from "@/components/CNumberInput.vue";
import CCheckbox from "@/components/CCheckbox.vue";

export default {
    name: "TestDriveView",
    components: {CCheckbox, CNumberInput, CButton, PhoneInput, CTextInput, CSelect, TextPopup},
    data() {
        return {
            showAgreementPopup: false,
            region: '',
            regions: [],
            seller: '',
            answer: '',
            dealers: [],
            name: '',
            surname: '',
            email: '',
            phone: '',
            enableDraw: false,
            isSubmiting: false,
        }
    },
    computed: {
        sellers() {
            let sellers = this.dealers;
            if(this.region) {
                sellers = this.dealers.filter(dealer => dealer.region === this.region);
            }
            return sellers.map((dealer) => ({
                id:dealer.id,
                name: `${dealer.name} - ${dealer.city}, ${dealer.zip}, ${dealer.street}`
            }));
        }
    },
    watch: {
        region() {
            const dealer = this.dealers.find(dealer => dealer.id === this.seller);
            if (dealer && dealer.region !== this.region) {
                this.seller = '';
            }
        },
        seller() {
            const dealer = this.dealers.find(dealer => dealer.id === this.seller);
            if(dealer) {
                this.region = dealer.region;
                if (this.$route.name !== 'test-drive-dealer' || this.$route.params.code !== dealer.code) {
                    this.$router.push({name: 'test-drive-dealer', params: {code: dealer.code}});
                }
            } else {
                this.$router.push({name: 'test-drive'});
            }
        }
    },
    methods: {
        async loadData(to) {
            this.regions = await this.$store.dispatch('dealers/getRegions');
            this.dealers = await this.$store.dispatch('dealers/getDealers');
            if(to.name === 'test-drive-dealer') {
                const dealer = this.dealers.find(dealer => dealer.code === to.params.code);
                this.seller = dealer.id;
                this.region = dealer.region;

            }
        },
        async submit() {
          this.isSubmiting = true;
            const data = {
                dealer: this.seller,
                firstName: this.name,
                lastName: this.surname,
                email: this.email,
                phone: this.phone,
                testDriveCount: this.answer,
                enableDraw: this.enableDraw,
            };
            await this.$store.dispatch('dealers/createTestDrive', data);
            await this.$router.push({name: 'test-drive-success'});
            this.isSubmiting = false;
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.loadData(to);
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.loadData(to);
        next();
    }
}
</script>

<style scoped>

</style>
