<template>
    <div class="testDrive">
        <div class="testDrive__header">
            <h1 class="testDrive__title">Byli jste přihlášeni na testovací jízdu</h1>
        </div>
        <div class="bg-dark-green mt-auto py-4">
            <div class="container">
                <div class="text-gray">Copyright ©2023 Škoda Auto a.s.</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "TestDriveView",
    data() {},
    computed: {},
    methods: {},
    beforeRouteEnter(to, from, next) {
        next();
    },
    beforeRouteUpdate(to, from, next) {
        next();
    }
}
</script>

<style scoped>

</style>
