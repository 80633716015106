<template>
    <div>
        <div v-if="page===0" class="sustain">
            <header class="header text-white">
                <h1 class="h4">Udržitelnost</h1>
            </header>

            <div class="textContainer mt-5">
                <div class="textContainer__heading">
                    Označte u následujících vět ty, které již nejsou pravda:
                </div>
                <p class="f-16">Lidé si myslí, že...</p>
                <p class="sustain__text">
                    Škoda Enyaq Coupé RS iV se nabije z 0 na 80 % veřejnou nabíjením (125 kW) za 29 min.
                </p>
            </div>
            <div class="sustain__menu">
                <c-button
                    @click="toStepTwo(false)"
                    class="sustain__menuBtn sustain__menuBtn--false"
                    :disabled="completed && canBeChargedByTime === true"
                >
                    <svg class="ico m-auto"><use xlink:href="#close-white"></use></svg>
                </c-button>
                <c-button
                    @click="toStepTwo(true)"
                    class="sustain__menuBtn"
                    :disabled="completed && canBeChargedByTime === false"
                >
                    <svg class="ico m-auto"><use xlink:href="#check"></use></svg>
                </c-button>
            </div>
            <answer-popup
                v-model="stepOnePopup"
                :correct="canBeChargedByTime === true"
                @close="page += 1"
            >
                ANO
            </answer-popup>
        </div>
        <div v-if="page===1" class="sustain is-second">
            <header class="header text-white">
                <h1 class="h4">Udržitelnost</h1>
            </header>
            <div class="textContainer mt-5">
                <div class="textContainer__heading">
                    Označte u následujících vět ty, které již nejsou pravda:
                </div>
                <p class="f-16">Lidé si myslí, že...</p>
                <p class="sustain__text">
                    Škoda Enyaq Coupé RS iV dojede přes 500 km na jedno nabití.
                </p>
            </div>
            <div class="sustain__menu">
                <c-button
                    @click="toStepTree(false)"
                    class="sustain__menuBtn sustain__menuBtn--false"
                    :disabled="completed && canReachDistance === true"
                >
                    <svg class="ico m-auto"><use xlink:href="#close-white"></use></svg>
                </c-button>
                <c-button
                    @click="toStepTree(true)"
                    class="sustain__menuBtn"
                    :disabled="completed && canReachDistance === false"
                >
                    <svg class="ico m-auto"><use xlink:href="#check"></use></svg>
                </c-button>
            </div>
            <answer-popup
                v-model="stepTwoPopup"
                :correct="canReachDistance === true"
                @close="page += 1"
            >
                ANO
            </answer-popup>
        </div>
        <div v-if="page===2" class="sustain is-third">
            <header class="header text-white">
                <h1 class="h4">Udržitelnost</h1>
            </header>
            <div class="textContainer mt-5">
                <div class="textContainer__heading">
                    Označte, zda je následující výrok pravdivý.
                </div>
                <p class="sustain__text">
                    V České Republice je aktuálně kolem 2 000 dobíjecích bodů.
                </p>
            </div>
            <div class="sustain__menu">
                <c-button
                    @click="toComplete(false)"
                    class="sustain__menuBtn sustain__menuBtn--false"
                    :disabled="completed && isTwoThousandPoints === true"
                >
                    <svg class="ico m-auto" width="24" height="24"><use xlink:href="#close-white"></use></svg>
                </c-button>
                <c-button
                    @click="toComplete(true)"
                    class="sustain__menuBtn"
                    :disabled="completed && isTwoThousandPoints === false"
                >
                    <svg class="ico m-auto" width="70" height="70"><use xlink:href="#check"></use></svg>
                </c-button>
            </div>
            <answer-popup
                v-model="stepThreePopup"
                :correct="isTwoThousandPoints === false"
                @close="complete"
            >
                přibližně 4000
            </answer-popup>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import stepValue from "@/utils/stepValue";
import CButton from "@/components/CButton";
import nextStep from "@/mixins/nextStep";
import AnswerPopup from "@/components/AnswerPopup";


const stepName = 'sustainability';

export default {
    name: "SustainabilityView",
    components: {AnswerPopup, CButton},
    mixins:[nextStep],
    data() {
        return {
            page: 0,
            stepOnePopup: false,
            stepTwoPopup: false,
            stepThreePopup: false,
        }
    },
    computed: {
        ...mapGetters(stepName, ['allSet']),
        canBeChargedByTime: stepValue(stepName, 'canBeChargedByTime'),
        canReachDistance: stepValue(stepName, 'canReachDistance'),
        isTwoThousandPoints: stepValue(stepName, 'isTwoThousandPoints'),
        completed: stepValue(stepName, 'completed'),
    },
    methods: {
        toStepTwo(status) {
            this.stepOnePopup = true;
            this.canBeChargedByTime = status;
        },
        toStepTree(status) {
            this.stepTwoPopup = true;
            this.canReachDistance = status;
        },
        toComplete(status) {
            this.stepThreePopup = true;
            this.isTwoThousandPoints = status;
            this.completed = true;
        },
        complete() {
            this.nextStep();
        }
    },
    beforeRouteEnter(to, from, next) {
        return next((vm) => {
            vm.page = 0;
        })
    }
}
</script>

<style scoped>

</style>
