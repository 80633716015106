<template>
  <label>
    <span v-if="label">{{label}}</span>
    <input
        v-model="innerValue"
        type="number"
        :required="required"
        :placeholder="placeholder"
        v-bind="{max, min}"
    >
  </label>
</template>

<script>
export default {
  name: "CNumberInput",
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: undefined
    },
    min: {
      type: Number,
      default: undefined
    },
  },
  emits: ['update:modelValue'],
  computed: {
    innerValue: {
      get() {
        return this.modelValue
      },
      async set(value) {
        await this.$emit('update:modelValue', value);
      }
    }
  }
}
</script>

<style scoped>

</style>
