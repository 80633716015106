<template>
    <div class="selectedModels" :class="color">
        <div class="selectedModels__container">
            <h1 class="selectedModels__title">{{ name }}</h1>
            <img :src="image" class="selectedModels__car">
            <p class="selectedModels__subTitle">
                <strong>{{ model }}</strong>
            </p>
            <p class="selectedModels__perex mt-4">
                váš vysněný vůz
            </p>
        </div>
        <div class="selectedModels__next">
            <c-button class="btn btn--borderWhite" @click="redirect">Dokončit</c-button>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import CButton from "@/components/CButton";
import nextStep from "@/mixins/nextStep";

export default {
    name: "SelectedModelView",
    components: {CButton},
    mixins: [nextStep],
    computed: {
        ...mapGetters('users', ['name', "isGameEnabled"]),
        ...mapGetters('skodaAndYou', ['person', 'color']),
        image() {
            const model =  this.person === 'businessman' ? 'enyaq-coupe-rs-iv' : 'enyaq-iv';
            return '/images/' + model + '/' + this.color + '.png'
        },
        model() {
            if (this.person === 'businessman') {
                return 'Škoda Enyaq Coupé RS iV';
            }
            return 'Škoda Enyaq iV';
        }
    },
    methods: {
        async redirect() {
            if (this.isGameEnabled) {
                return await this.$router.push({name: 'test-drive-question'});
            }
            return await this.$router.push({name: 'completed'});
        }
    },
}
</script>

<style scoped>

</style>
