<template>
    <svg-sprite/>
    <main-menu v-if="hasHeader"/>
    <router-view/>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import SvgSprite from "@/components/SvgSprite";

export default {
    components: {SvgSprite, MainMenu},
    computed: {
        hasHeader() {
            return !this.$route.meta.noHeader;
        }
    }
}
</script>

<style lang="scss">

</style>
