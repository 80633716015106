<template>
    <div>
        <form class="coop" @submit.prevent="submit">
            <header class="header text-white mb-5">
                <h1 class="h4">Soutěžní otázka</h1>
            </header>
            <div class="textContainer">
                <div class="textContainer__heading">
                    Kolik testovacích jízd v rámci akce Škoda Nabité dny uskutečníme v období od 1. 6. do 31. 10. 2023?
                </div>
                <div class="coop__slider form-inverse">
                    <c-number-input
                        v-model="testDriveCount"
                        label="Váš tip"
                        placeholder=""
                        class="input"
                        min="0"
                        max="1000000"
                        required
                    />
                </div>
            </div>



            <c-button class="btn btn--green">
                Odeslat
            </c-button>

        </form>


    </div>
</template>

<script>

import CButton from "@/components/CButton"
import CNumberInput from "@/components/CNumberInput.vue";
import {mapGetters} from "vuex";



export default {
    name: "TestDriveQuestionView",
    components: {CNumberInput, CButton},
    data() {
        return {
          testDriveCount: 0,
        }
    },
    computed: {
        ...mapGetters("users", ["isGameEnabled"]),
    },
    methods: {
        async submit() {
            await this.$store.dispatch("users/updateCurrentUser", {testDriveCount: this.testDriveCount});
            await this.$router.push({name: "completed"});
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!vm.isGameEnabled) {
                vm.$router.push({name: "venue-plan"});
            }
        });
    },
};
</script>

<style scoped>

.coop {
    --background-image: url('../assets/images/test-drive-question-bg.jpg');
}

</style>
